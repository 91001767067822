import { GatsbySeo } from 'gatsby-plugin-next-seo'
import OurStores from 'src/components/OurStores'

function Stores() {
  return (
    <>
      <GatsbySeo
        language="pt-br"
        title="Nossas Lojas | Decathlon"
        noindex
        nofollow
      />
      <OurStores />
    </>
  )
}

export default Stores
